import React from 'react';
import './Button.css';

export function Button({ style, color, alt, Icon, label, className='', onClick }) {
    let buttonClasses;

    if (alt) {
        buttonClasses = `button alt ${style} ${color} ${className}`;
    } else {
        buttonClasses = `button ${style} ${color} ${className}`;
    };
    
    if (Icon) {
        return (
            <button className={buttonClasses} onClick={onClick}>
                <Icon/>
                <div className="button_label">{label}</div>
            </button>
        );
    } else {
        return (
            <button className={buttonClasses} onClick={onClick}>
                <div className="button_label">{label}</div>
            </button>
        );
    }
}