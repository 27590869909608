import React from 'react';
import './HomePage.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import engage from '../assets/home/home-engage.png';
import engagebg from '../assets/backgrounds/bg-1.svg';
import crossDisc from '../assets/home/home-cross-disciplinary.png'; 
import programming from '../assets/home/home-programming.png';
import programmingbg from '../assets/backgrounds/bg-2.svg';
import tinyfarm from '../assets/home/home-tinyfarm.png';  
import tinyfarmshadow from '../assets/home/home-tinyfarm-shadow.png';  
import tinyfarmhub from '../assets/home/home-tinyfarm-hub.png';
import tinyfarmhubshadow from '../assets/home/home-tinyfarm-hub-shadow.png';
import podsnutrients from '../assets/home/home-plant-pods-nutrients.png';
import podsnutrientsshadow from '../assets/home/home-plant-pods-nutrients-shadow.png';
import {ContactForm} from './sections/ContactForm.js'
import { Image } from './uitools/Image';
import { Background } from './uitools/Background';
import { Button } from './uitools/Button';
import NewsItem from './sections/NewsItem';

function HomePage() {
  const { t } = useTranslation();
  
  return (
    <div className="container gap_96">
      <Background imgSrc={engagebg} alignX="center" alignY="top" />
      <Background imgSrc={programmingbg} alignX="center" alignY="bottom" offsetBottom="-417"/>
      <div id="engage" className="wrapper wrapper_m align_center gap_64">
        <div className="column flex_1_3"><Image imgSrc={engage} alt={'Home engage'}/></div>
        <div className="column">
          <h3>{t('home.engage_heading')}</h3>
          <p>{t('home.engage_subtext')}</p>
        </div>
      </div>
      <div id="cross_disc" className="wrapper wrapper_m align_center dir_row_reverse gap_64">
        <div className="column flex_1_3"><Image imgSrc={crossDisc} alt={'Cross disciplinary'}/></div>
        <div className="column">
          <h3>{t('home.cross_disciplinary_heading')}</h3>
          <p>{t('home.cross_disciplinary_subtext')}</p>
        </div>
      </div>
      <div id="programming" className="wrapper wrapper_m align_center gap_64">
        <div className="column flex_1_3"><Image imgSrc={programming} alt={'Programming'}/></div>
        <div className="column">
          <h3>{t('home.programming_heading')}</h3>
          <p>{t('home.programming_subtext')}</p>
        </div>
      </div>
    </div>
  );
}

function Products() {
  let navigate = useNavigate();
  const { t } = useTranslation();

  function handleNavigation(path) {
    navigate(path);
  }

  return (
    <div id="products" className="container bg_purple_20">
      <div className="wrapper gap_48 dir_col">
        <div className="wrapper dir_col text_align_center">
          <h1>{t('home.products_heading')}</h1>
          <p>{t('home.products_subtext')}</p>
        </div>
        <div className="wrapper justify_stretch gap_24">
          <div className="card purple text_align_center">
            <Image imgSrc={tinyfarm} bgSrc={tinyfarmshadow} blendMode="multiply" alt={'Tinyfarm lab'}/>
            <div className="wrapper dir_col">
              <h4>{t('home.products_tinyfarm_heading')}</h4>
              <p className="small">{t('home.products_tinyfarm_subtext')}</p>
            </div>
            <Button style="primary" color="green" label={t('buttons.learn_more')} onClick={() => handleNavigation("/tinyfarm")}/>
          </div>
          <div className="card purple text_align_center">
            <Image imgSrc={tinyfarmhub} bgSrc={tinyfarmhubshadow} blendMode="multiply" alt={'tinyfarm hub'}/>
            <div className="wrapper dir_col">
              <h4>{t('home.products_tinyfarmhub_heading')}</h4>
              <p className="small">{t('home.products_tinyfarmhub_subtext')}</p>
            </div>
            <Button style="primary" color="green" label={t('buttons.learn_more')} onClick={() => handleNavigation("/app")}/>
          </div>
          <div className="card purple text_align_center">
            <Image imgSrc={podsnutrients} bgSrc={podsnutrientsshadow} blendMode="multiply" alt={'Pods & nutrients'}/>
            <div className="wrapper dir_col">
              <h4>{t('home.products_pods_nutrients_heading')}</h4>
              <p className="small">{t('home.products_pods_nutrients_subtext')}</p>
            </div>
            <Button style="primary" color="green" label={t('buttons.buy_now')} onClick={() => handleNavigation("/shop")}/>
          </div>
        </div>
      </div>
    </div>
  );
}

function HomePageWithLab() {
return (
  <>
    <HomePage />
    <Products/>
    <ContactForm/>
</>
);
}

export default HomePageWithLab;
