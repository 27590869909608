import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

const languageDetectorOptions = {
  order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

  lookupQuerystring: 'lng',
  lookupCookie: 'i18n',
  lookupLocalStorage: 'i18nLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], 

  cookieMinutes: 10,
  cookieDomain: window.location.hostname,

  htmlTag: document.documentElement,

  checkWhitelist: true,
};


const translationFiles = require.context('./translations', true, /\.json$/);
const resources = translationFiles.keys().reduce((acc, file) => {
  const languageCode = file.replace('./', '').replace('.json', '');
  acc[languageCode] = translationFiles(file);
  return acc;
}, {});

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    debug: true,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    },
    detection: languageDetectorOptions,
  });

export default i18n;
