import React from 'react';
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2';

export const PhoneDropdown = ({ phone, setPhone }) => {
  const handleOnChange = (phone) => {
    setPhone(phone);
  }

  return (
    <PhoneInput
      country={'dk'}
      value={phone}
      containerClass="contact_form_input"
      name="phone"
      placeholder='+45 32 12 34 56'
      inputClass="phone_form_input"
      onChange={handleOnChange} />
  );
}

