import React, { useEffect } from 'react';
import './UserManual.css'
import { useTranslation } from 'react-i18next';
import {Button} from './uitools/Button';
import { Background } from './uitools/Background';
import herobg from '../assets/backgrounds/bg-5.svg';
import imgSrc from '../assets/user-manual/Tinyfarm_User_Manual-thumbnail.jpg'
import { Image } from './uitools/Image';
import {ReactComponent as Download} from '../assets/icons/download-stroke-medium.svg';

const FileDownload = () => {
    const { t } = useTranslation();

    // Function to trigger the file download
    const triggerDownload = () => {
        const url = '/Tinyfarm_User_Manual.pdf';
        const fileName = 'Tinyfarm_User_Manual.pdf';

        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = fileName;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    };

    useEffect(() => {
        // Automatically trigger the download when the component mounts
        triggerDownload();
    }, []);

  return (
    <>
    <div id="hero" className="container user-manual">
        <Background imgSrc={herobg} alignX="center" alignY="top" offsetTop="-595" />
        <div className="wrapper wrapper_xs dir_col gap_32">
            <Image imgSrc={imgSrc} className={'pdf-download-thumb'} alt={'Tinyfarm User Manual'}/>
            <div className="wrapper dir_col text_align_center">
                <h1>{t('userManual.heading')}</h1>
            </div>
            <div className="wrapper dir_col text_align_center gap_24">
                <p className="m0">{t('userManual.description')}</p>
                <div className="wrapper justify_center align_center gap_16">
                <Button
                style="primary"
                color="green"
                label={t('userManual.button')}
                Icon={Download}
                onClick={triggerDownload}/>
                </div>
            </div>
        </div>
    </div>
    </>
  );
};

export default FileDownload;