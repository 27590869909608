import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NewsItem.css';
import { Image } from '../uitools/Image';
import { Button } from '../uitools/Button';

function NewsItem({imgSrc, imgAlt, date, title, text, url}) {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate(`/news${url}`);
    }

    return (
        <div className="news_item wrapper wrapper_m gap_32">
            <div className="column flex_1_3"><Image imgSrc={imgSrc} alt={imgAlt}/></div>
            <div className="column">
                <p className='preheader'>{date}</p>
                <h3>{title}</h3>
                <p className='small'>{text}</p>
                <Button style="secondary" color="green" label="Read more" onClick={handleButtonClick}/>
            </div>
        </div>
    );
}

export default NewsItem;
