import './FormFillout.css';
import { FilloutStandardEmbed } from "@fillout/react";

function FormFillout ({url}) {
    return (
        <div className='form-wrapper form-fillout'>
            <FilloutStandardEmbed filloutId={url} />
        </div>
    );
}

export default FormFillout;