import React, { useState } from 'react';
import faqbg1 from '../../assets/backgrounds/bg-2.svg';
import { useTranslation } from 'react-i18next';
import { Background } from '../uitools/Background';
import VideoSection from '../uitools/VideoSection';

function VideoTutorials() {
  const { t, i18n } = useTranslation();

  return (
    <div id="video-tutorials" className="container gap_96">
      <Background imgSrc={faqbg1} alignX="center" alignY="top" offsetTop="20" />
      <VideoSection playlistId={t("videoTutorials.playlistId.gettingStarted")}/>
      <VideoSection playlistId={t("videoTutorials.playlistId.troubleshooting")}/>
    </div>
  );
}

export default VideoTutorials;
