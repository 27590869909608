import React, { useState, useEffect } from 'react';
import { fetchPlaylistDetails, fetchPlaylistVideos } from './YouTubeDataService';
import AspectRatio from './aspectRatio';
import "./VideoSection.css"

const VideoSection = ({ playlistId, aspectRatioWidth, aspectRatioHeight, aspectRatioBasedOn }) => {
  const [playlistDetails, setPlaylistDetails] = useState({});
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const details = await fetchPlaylistDetails(playlistId);
      const videos = await fetchPlaylistVideos(playlistId);
      setPlaylistDetails(details);
      setVideos(videos);
    };
    fetchData();
  }, [playlistId]);

  function trimAfterTime(description) {
    const timeMarker = "\n";
    const index = description.indexOf(timeMarker);
    if (index === -1) {
      // If the time marker is not found, return the original description
      return description;
    }
    // Add the length of the timeMarker to include it in the result
    return description.substring(0, index);
  }

  return (
    <div className="videos-section wrapper dir_col gap_32">
      <h3 className='m0'>{playlistDetails.title}</h3>
      <div className="videos-container wrapper">
        {videos.map((video, index) => (
          <div key={index}>
            <div className="video-wrapper">
              <AspectRatio width={aspectRatioWidth || "16"} height={aspectRatioHeight || "9"} basedOn={aspectRatioBasedOn || "width"}/>
              <iframe
                  width="100%"
                  height="100%"
                  src={`https://www.youtube.com/embed/${video.resourceId.videoId}?rel=0&enablejsapi=1&playsinline=1&showInfo=0&controls=1&fullscreen=1"frameborder="0"allowfullscreen="true"`}
                  title={video.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  className='video-embed'
              />
            </div>
            <p className='video-title bold'>{video.title}</p>
            {video.description && <p className='video-description small'>{trimAfterTime(video.description)}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoSection;
