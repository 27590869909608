import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Footer.css';
import { ReactComponent as Logo } from '../assets/logo/tinyfarms-logo-white.svg';
import { ReactComponent as Email } from '../assets/icons/mail-fill-medium.svg';
import { ReactComponent as Phone } from '../assets/icons/phone-fill-medium.svg';
import { ReactComponent as LinkedIn } from '../assets/icons/logo_linkedin-fill-medium.svg';
import LanguagePicker from './uitools/Languages';
import { useTranslation } from 'react-i18next';
import * as contactInfo from '../contactInfo';


function Footer() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  function handleNavigation(path) {
    navigate(path);
  }

  return (
    <div id="footer" className="container">
      <div className="wrapper gap_64">
        <div className="column gap_16 brand">
          <Logo className="navbar_logo" onClick={() => handleNavigation("/")} />
          <p className="tiny color_alt">{t('footer.tagline')}</p>
        </div>
        <div className="column gap_8 socials">
          <a href={contactInfo.emailURL} className="wrapper gap_8">
            <Email className="foot_language_img" />
            <p className="xsmall color_alt">{contactInfo.email}</p>
          </a>
          <a href={contactInfo.phoneURL} className="wrapper gap_8">
            <Phone className="foot_language_img" />
            <p className="xsmall color_alt">{contactInfo.phone}</p>
          </a>
          <a href={contactInfo.linkedInURL} target="_blank" className="wrapper gap_8">
            <LinkedIn className="foot_language_img" />
            <p className="xsmall color_alt">@{contactInfo.linkedInHandle}</p>
          </a>
          <LanguagePicker theme="white" />
        </div>
        <div className="column gap_4 links">
          <p className="footer_link small bold color_alt" onClick={() => handleNavigation("/tinyfarm")}>{t('footer.tinyfarm')}</p>
          <p className="footer_link small bold color_alt" onClick={() => handleNavigation("/app")}>{t('footer.tinyfarm_hub')}</p>
          <p className="footer_link small bold color_alt" onClick={() => handleNavigation("/shop")}>{t('footer.shop')}</p>
          <p className="footer_link small bold color_alt" onClick={() => handleNavigation("/support")}>{t('footer.support')}</p>
          <p className="footer_link small bold color_alt" onClick={() => handleNavigation("/news")}>{t('footer.news')}</p>
          <p className="footer_link small bold color_alt" onClick={() => window.location.href = "https://app.tinyfar.ms/login"}>{t('footer.log_in')}</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
