// import './NewsPage.css'
import React, { useEffect, useState } from "react";
import {useTranslation} from 'react-i18next';
import {ContactForm}  from './sections/ContactForm'
import TeaserSection from './sections/TeaserSection'
import NewsItem from './sections/NewsItem'
import bg1 from '../assets/backgrounds/bg-5.svg';
import bg2 from '../assets/backgrounds/bg-2.svg';
import {Button} from './uitools/Button'
import { Background } from './uitools/Background';
import { useNavigate } from 'react-router-dom';
import tinyfarmteaser from '../assets/tinyfarm-hub/tinyfarm-teaser.png';
import tinyfarmteasershadow from '../assets/tinyfarm-hub/tinyfarm-teaser-shadow.png';


function NewsPage() {

  let navigate = useNavigate();
  // Use the useTranslation hook
  const { t, i18n } = useTranslation();

  function handleNavigation(path) {
      navigate(path);
  }

  const teaserContent = 
  <>
      <div className="wrapper dir_col">
        <h2>{t('tinyfarm_hub.teaser_header')}</h2>
        <p>{t('tinyfarm_hub.teaser_subtext')}</p>
      </div>
      <Button style="primary" color="green" label={t('tinyfarm_hub.teaser_button')} onClick={() => handleNavigation("/tinyfarm")}/>
  </>;
  
  const [articles, setArticles] = useState([]);

  useEffect(() => {
      const getArticles = async () => {
        try {
          console.log(i18n.language);
          const response = await fetch(`https://cdn.tinyfar.ms/api/articles?locale=${i18n.language}&populate=shorthand_image`);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          const articleData = data.data.map(item => item.attributes);
          setArticles(articleData);
        } catch (err) {
          console.error(`Error fetching articles: ${err}`);
        }
      };
      
      getArticles();
  }, [i18n.language]);
    
  return (
    <>
      <div id="hero" className="container">
          <Background imgSrc={bg1} alignX="center" alignY="top" offsetTop="-595" />
          <div className="wrapper dir_col text_align_center">
              <p className='preheader'>{t('news.preheader')}</p>
              <h1>{t('news.pageTitle')}</h1>
          </div>
      </div>
      <div id="news" className="container gap_64">
          <Background imgSrc={bg2} alignX="center" alignY="bottom" offsetBottom="-417"/>
          {articles.map(article => (
              <NewsItem
              imgSrc={article.shorthand_image.data.attributes.url}
              date={article.date}
              title={article.title}
              text={article.shorthand_text}
              url={'/' + article.url}
              />
          ))}
      </div>
      <TeaserSection imgSrc={tinyfarmteaser} imgBgSrc={tinyfarmteasershadow} imgBlendMode="multiply" content={teaserContent} reverse/>
      <ContactForm/>
    </>
  );
}

export default NewsPage;
