import axios from 'axios';

const YOUTUBE_API_KEY = 'AIzaSyCYP-4PyoCPa722Sw_rCuMpazCZpRT8a7A';
const YOUTUBE_API_URL = 'https://www.googleapis.com/youtube/v3';

const fetchPlaylistDetails = async (playlistId) => {
  try {
    const response = await axios.get(`${YOUTUBE_API_URL}/playlists`, {
      params: {
        part: 'snippet',
        id: playlistId,
        key: YOUTUBE_API_KEY,
      },
    });
    return response.data.items[0].snippet; // Returns playlist name and description
  } catch (error) {
    console.error('Error fetching playlist details:', error);
  }
};

const fetchPlaylistVideos = async (playlistId) => {
  try {
    const response = await axios.get(`${YOUTUBE_API_URL}/playlistItems`, {
      params: {
        part: 'snippet',
        playlistId: playlistId,
        maxResults: 50, // Adjust based on your needs
        key: YOUTUBE_API_KEY,
      },
    });
    return response.data.items.map(item => item.snippet); // Returns an array of video details
  } catch (error) {
    console.error('Error fetching playlist videos:', error);
  }
};

export { fetchPlaylistDetails, fetchPlaylistVideos };