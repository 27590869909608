import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';

const Cookies = () => {
  const { t } = useTranslation();

  return (
    <CookieConsent
      location="bottom"
      buttonText={t('cookie.understand')}
      cookieName="cookieConsent"
      style={{
        background: "var(--purple_50)",
        borderTop: "2px solid var(--green_50)",
        padding: "0"
      }}
      contentStyle={{
        color: "var(--white)",
        fontSize: "18px",
        lineHeight: "140%",
        fontWeight: "300",
        padding: "0",
        margin: "16px 24px"
      }}
      buttonStyle={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        cursor: "pointer",
        padding: "0 24px",
        borderRadius: "8px",
        border: "0",
        height: "48px",
        boxSizing: "border-box",
        whiteSpace: "nowrap",
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: "700",
        color: "var(--white)",
        background: "var(--green_50)",
        margin: "16px 24px"
      }}
      expires={150}
    >
      {t('cookie.message')}
    </CookieConsent>
  );
};

export default Cookies;