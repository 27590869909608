import React, { useEffect, useState } from 'react';
import './NewsArticlePage.css';
import { useTranslation } from 'react-i18next';
import { ContactForm } from './sections/ContactForm';
import TeaserSection from './sections/TeaserSection';
import bg1 from '../assets/backgrounds/bg-5.svg';
import bg2 from '../assets/backgrounds/bg-2.svg';
import { Button } from './uitools/Button';
import { Image } from './uitools/Image';
import { Background } from './uitools/Background';
import { useNavigate, useParams } from 'react-router-dom';
import tinyfarmteaser from '../assets/tinyfarm-hub/tinyfarm-teaser.png';
import tinyfarmteasershadow from '../assets/tinyfarm-hub/tinyfarm-teaser-shadow.png';
import { Helmet } from 'react-helmet';

function NewsArticlePage() {
  const { url } = useParams();
  const [article, setArticle] = useState({});
  const { t, i18n } = useTranslation();
  const [firstImageSrc, setFirstImageSrc] = useState(null);

  let navigate = useNavigate();

  function handleNavigation(path) {
    navigate(path);
  }

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await fetch(
          `https://cdn.tinyfar.ms/api/articles?locale=${i18n.language}&url=${url}&populate=deep`
        );
        const data = await response.json();
        if (data.data.length > 0) {
          const articleData = data.data[0].attributes;
          setArticle(articleData);

          // find the first image in the article and set it as the og:image
          const firstImage = articleData.content.find(
            (section) => section.__component === 'image.image'
          );
          if (firstImage) {
            setFirstImageSrc(firstImage.src && firstImage.src.url);
          }
        }
      } catch (err) {
        console.error(`Error fetching article: ${err}`);
      }
    };

    fetchArticle();
  }, [url, i18n.language]);

  const teaserContent = (
    <>
      <div className="wrapper dir_col">
        <h2>{t('tinyfarm_hub.teaser_header')}</h2>
        <p>{t('tinyfarm_hub.teaser_subtext')}</p>
      </div>
      <Button
        style="primary"
        color="green"
        label={t('tinyfarm_hub.teaser_button')}
        onClick={() => handleNavigation('/tinyfarm')}
      />
    </>
  );

  return (
    <>
      <Helmet>
        {firstImageSrc && <meta property="og:image" content={firstImageSrc} />}
        {article && <meta property="og:title" content={article.title} />}
      </Helmet>
      <div id="news_article" className="container align_text_left gap_48">
        <Background imgSrc={bg1} alignX="center" alignY="top" offsetTop="-595" />
        <Background imgSrc={bg2} alignX="center" alignY="bottom" offsetBottom="-400" />

        {article && (
          <>
            <div className="wrapper wrapper_s dir_col">
              <p className="preheader">{article.date}</p>
              <h1>{article.title}</h1>
            </div>
            <div className="wrapper wrapper_s dir_col">
              <p>{article.shorthand_text}</p>
              {article.content &&
                article.content.map((section, index) => {
                  switch (section.__component) {
                    case 'paragraph.paragraph':
                      return <p key={index}>{section.paragraph_text}</p>;
                    case 'quote.quote':
                      return (
                        <blockquote key={index}>
                          <h6>{section.quote_text}</h6>
                          <cite>{section.quote_source}</cite>
                        </blockquote>
                      );
                      case 'image.image':
                        return section.image_image ? (
                          <img
                            src={section.image_image.data.attributes.url}
                            alt={section.image_image.data.attributes.alternativeText || 'image'} // Use alternativeText for alt
                            className="news_image"
                            key={index}
                          />
                        ) : null;
                  }
                })}
            </div>
          </>
        )}
      </div>
      <TeaserSection
        imgSrc={tinyfarmteaser}
        imgBgSrc={tinyfarmteasershadow}
        imgBlendMode="multiply"
        content={teaserContent}
        reverse
      />
      <ContactForm />
    </>
  );
}

export default NewsArticlePage;
