import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './HelpPage.css';
import { TechContactForm, HelpContactForm } from './sections/ContactForm';
import FAQSection from './sections/FAQ';
import VideoTutorialsSection from './sections/VideoTutorials';
import { ReactComponent as MailIcon } from '../assets/icons/mail-stroke-medium.svg';
import { ReactComponent as SettingsIcon } from '../assets/icons/settings-stroke-medium.svg';
import { ReactComponent as HelpIcon } from '../assets/icons/help_circle-stroke-medium.svg';
import { ReactComponent as PlayIcon } from '../assets/icons/play-stroke-medium.svg';
import {ReactComponent as Download} from '../assets/icons/download-stroke-medium.svg';
import { Chip } from './uitools/Chip';
import { useTranslation } from 'react-i18next';

function HelpPage() {
  const { t } = useTranslation();
  const [activeButton, setActiveButton] = useState('video-tutorials');
  const navigate = useNavigate();

  return (
    <>
      <div id="help_hero" className="container bg_purple_20">
        <div className="wrapper dir_col text_align_center gap_32">
          <div className='wrapper dir_col gap_16'>
            <h1 className='m0'>{t('helpPage.pageTitle')}</h1>
            <p>{t('helpPage.pageDescription')}</p>
          </div>
          <div className="chips_wrapper">
            <Chip
              Icon={PlayIcon}
              label={t('helpPage.buttons.videoTutorials')}
              onClick={() => setActiveButton('video-tutorials')}
              className={activeButton === 'video-tutorials' ? 'active' : ''}
            />
            <Chip
              Icon={Download}
              label={t('helpPage.buttons.userManual')}
              onClick={() => navigate("/user-manual")}
            />
            <Chip
              Icon={HelpIcon}
              label={t('helpPage.buttons.faqs')}
              onClick={() => setActiveButton('faqs')}
              className={activeButton === 'faqs' ? 'active' : ''}
            />
            <Chip
              Icon={SettingsIcon}
              label={t('helpPage.buttons.technicalIssues')}
              onClick={() => setActiveButton('tech-contact')}
              className={activeButton === 'tech-contact' ? 'active' : ''}
            />
            <Chip
              Icon={MailIcon}
              label={t('helpPage.buttons.contactUs')}
              onClick={() => setActiveButton('help-contact')}
              className={activeButton === 'help-contact' ? 'active' : ''}
            />
          </div>
        </div>
      </div>
      {activeButton === 'video-tutorials' && <VideoTutorialsSection />}
      {activeButton === 'faqs' && <FAQSection />}
      {activeButton === 'tech-contact' && <TechContactForm />}
      {activeButton === 'help-contact' && <HelpContactForm />}
    </>
  );
}

export default HelpPage;
