import React, { useState, useEffect, useContext, useCallback } from 'react';
import './ShopItems.css';
import { Image } from '../uitools/Image';
import { Button } from '../uitools/Button'; 
import { ReactComponent as Chevron } from '../../assets/icons/chevron_down-stroke-small.svg';
import { ReactComponent as Arrow } from '../../assets/icons/arrow_right-stroke-medium.svg';
import { ReactComponent as Plus } from '../../assets/icons/plus-fill-small.svg';
import { ReactComponent as Minus } from '../../assets/icons/minus-fill-small.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const ShopText = ({ title, items, features, url }) => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    if (url) {
      navigate(url);
    }
  };

  return (
    <div className="shop_text_section">
      {title && <p className="small bold shop_text_title">{title}</p>}
      <ul className="xsmall">
        {items.map(item => <li key={item}>{item}</li>)}
      </ul>
      {features && <div className="shop_text_features" onClick={handleRedirect}>{features}</div>}
    </div>
  );
};


export const ShopItem = ({ imgSrc, bgSrc, alt, title, price, currency, sections }) => {
    const { t } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);
    const [value, setValue] = useState(0);
    const [prevValue, setPrevValue] = useState(0);
    const itemPrice = Number(price.replace(',', ''));
    const { addItem } = useContext(ShopPriceContext);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const handleInputChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setValue(isNaN(newValue) || newValue < 0 ? 0 : newValue);
  };

  useEffect(() => {
    const difference = value - prevValue;
    addItem({
      title: title,
      quantity: difference,
      totalPrice: itemPrice * difference,
    });
    setPrevValue(value);
  }, [value, addItem, itemPrice, prevValue, title]);

  return (
    <div className={`shop_item ${value > 0 ? 'active' : ''}`}>
      <div className="shop_item_header">
        <Image imgSrc={imgSrc} bgSrc={bgSrc} blendMode="multiply" alt={alt} className='shop_item_image' />
        <div className="shop_item_title_container">
          <h4>{t(title)}</h4>
          <div className="shop_item_details_toggle show_desktop" onClick={toggleExpanded}>
            <p className="xsmall bold">{isExpanded ? t('shop.hide_details') : t('shop.show_details')}</p>
            <Chevron className={isExpanded ? 'opened' : ''} />
          </div>
        </div>
        <div className="shop_price_container">
          <div className="shop_price">
            <div className="h4 shop_price_price">{price}</div>
            <div className="h4 shop_price_currency">{currency}</div>
          </div>
          <div className="shop_number_container">
            <button className="shop_input_button" onClick={() => setValue(value > 0 ? value - 1 : 0)}><Minus /></button>
            <input className="shop_input" type="number" value={value} onChange={handleInputChange} />
            <button className="shop_input_button" onClick={() => setValue(value + 1)}><Plus /></button>
          </div>
        </div>
        <div className="shop_item_details_toggle hide_desktop" onClick={toggleExpanded}>
            <p className="xsmall bold">{isExpanded ? t('shop.hide_details') : t('shop.show_details')}</p>
            <Chevron className={isExpanded ? 'opened' : ''} />
        </div>
      </div>
      {isExpanded && (
        <div className="shop_item_text">
          {sections.map(section => <ShopText key={section.title} {...section} />)}
        </div>
      )}
    </div>
  );
};

export const ShopItemsFooter = ({ currency }) => {
  const { t } = useTranslation();
  const { cart } = useContext(ShopPriceContext);
  const { totalPrice } = cart; // Extract totalPrice from the cart object

  return (
    <div className="shop_items_footer shop_item_last">
      <div className="shop_items_subtotal">{t('shop.subtotal')}</div>
      <div className="shop_price_container">
        <div className="shop_price shop_price_footer">
          <div className="h4 shop_price_price">{totalPrice.toLocaleString()}</div>
          <div className="h4 shop_price_currency">{currency}</div>
          <div className="xsmall shop_price_shipping">+ {t('shop.shipping')}</div>
        </div>
      </div>
    </div>
  );
};


export const ShopReview = () => {
  const { t } = useTranslation();
  const { cart } = useContext(ShopPriceContext);
  const { totalPrice, items } = cart;

  const vatRate = 0.25; 
  const vat = totalPrice * vatRate;
  const grandTotal = totalPrice + vat;

  return (
    <div id="review" className="wrapper dir_col gap_24">
      <div className="wrapper dir_col gap_8">
        <div className="shop_step">
          <div className="shop_step_number">3</div>
          <h4>{t('shop.review_order')}</h4>
        </div>
        <p className="small">{t('shop.order_confirmation')}</p>
      </div>
      <div className="review_section">
        <div className="wrapper dir_col gap_8 review_section_section review_section_items">
        {items.map((item, index) => (
          <React.Fragment key={index}>
          <input
            type="hidden"
            name={`item-${item.title}`}
            value={`${item.title}, ${t('shop.amount')}: ${item.quantity} ${t('shop.price')}: ${item.totalPrice.toLocaleString()}`}
          />
          </React.Fragment>
        ))}
          {items.map(item => (
            <div key={item.title} className="review_section_row">
              <p className="review_section_row_item">{item.title}</p>
              <div className="review_section_row_total">
                <div className="review_section_price_wrapper">
                  <p className="review_section_price price_green">{item.totalPrice.toLocaleString()}</p>
                  <p className="review_section_price_unit price_green">{t('shop.currency')}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="wrapper dir_col review_section_section">
          <div className="review_section_row">
            <p className="review_section_row_item">{t('shop.subtotal')}</p>
            <div className="review_section_row_subtotal">
              <div className="review_section_price_wrapper">
                <p className="review_section_price">{totalPrice.toLocaleString()}</p>
                <p className="review_section_price_unit">{t('shop.currency')}</p>
              </div>
            </div>
          </div>
          <div className="review_section_row">
            <p className="review_section_row_item">{t('shop.vat')}</p>
            <div className="review_section_row_subtotal">
              <div className="review_section_price_wrapper">
                <p className="review_section_price">{vat.toLocaleString()}</p>
                <p className="review_section_price_unit">{t('shop.currency')}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="review_section_total">
          <div className="shop_items_subtotal">{t('shop.total')}</div>
          <div className="shop_price_container">
            <div className="shop_price shop_price_footer">
              <div className="h4 shop_price_price">{grandTotal.toLocaleString()}</div>
              <div className="h4 shop_price_currency">{t('shop.currency')}</div>
              <div className="xsmall shop_price_shipping">+ {t('shop.shipping')}</div>
            </div>
          </div>
        </div>
        <input type="hidden" name="subtotal" value={totalPrice.toLocaleString()} />
        <input type="hidden" name="vat" value={vat.toLocaleString()} />
        <input type="hidden" name="total" value={grandTotal.toLocaleString()} />
      </div>
        <Button type="submit" 
        style="primary" 
        color="purple" 
        Icon={Arrow} 
        label={t('shop.place_order')} 
        className={"align_self_start row_reverse"} />
    </div>
  );
};


export const ShopPriceContext = React.createContext();
export const ShopProvider = ({ children }) => {
  const [cart, setCart] = useState({ totalPrice: 0, items: [] });
  
  const addItem = useCallback((item) => {
    setCart((prevCart) => {
      const existingItemIndex = prevCart.items.findIndex(
        (i) => i.title === item.title
      );

      if (existingItemIndex > -1) {
        // Item already exists, update the quantity and price
        const newItems = [...prevCart.items];
        newItems[existingItemIndex].quantity += item.quantity;
        newItems[existingItemIndex].totalPrice += item.totalPrice;

        return {
          totalPrice: prevCart.totalPrice + item.totalPrice,
          items: newItems,
        };
      } else {
        // Item doesn't exist, add it to the items array
        return {
          totalPrice: prevCart.totalPrice + item.totalPrice,
          items: [...prevCart.items, item],
        };
      }
    });
  }, []);
  
    useEffect(() => {
      setCart({ totalPrice: 0, items: [] });
    }, []);
  
    
  
    return (
      <ShopPriceContext.Provider value={{ cart, addItem }}>
        {children}
      </ShopPriceContext.Provider>
    );
  };
