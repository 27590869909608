import './ShopPage.css'
import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useForm } from '@formspree/react';
import {AddressContactForm}  from './sections/ContactForm'
import {ShopItem, ShopItemsFooter, ShopReview}  from './sections/ShopItems'
import productsbg from '../assets/backgrounds/bg-5.svg';
import reviewbg from '../assets/backgrounds/bg-4.svg';
import starterpack from '../assets/shop/shop-starter-pack.png';
import starterpackshadow from '../assets/shop/shop-starter-pack-shadow.png';
import plantpodpack from '../assets/shop/shop-plant-pod-pack.png';
import plantpodpackshadow from '../assets/shop/shop-plant-pod-pack-shadow.png';
import nutrientpack from '../assets/shop/shop-nutrient-pack.png';
import nutrientpackshadow from '../assets/shop/shop-nutrient-pack-shadow.png';
import plantpodnutrientpack from '../assets/shop/shop-plant-pod-nutrient-pack.png';
import plantpodnutrientpackshadow from '../assets/shop/shop-plant-pod-nutrient-pack-shadow.png';
import { Background } from './uitools/Background';
import { useTranslation } from 'react-i18next';

function ShopPage() {
    const { t } = useTranslation();
    const handleValueChange = (newValue) => {
        console.log(newValue); 
    };
    const [state, handleSubmit] = useForm('xjvdqrwe');
    const navigate = useNavigate();
    useEffect(() => {
        if (state.succeeded) {
            navigate('/confirmation');
        }
    }, [state.succeeded]);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        await handleSubmit(e);
        
        
    };
    return (
      <>
        <div id="hero" className="container">
            <Background imgSrc={productsbg} alignX="center" alignY="top" offsetTop="-595" />
            <div className="wrapper dir_col text_align_center">
                <h1>{t('shop.hero_title')}</h1>
                <p>{t('shop.hero_subtitle')}</p>
            </div>
        </div>
        <div id="shop" className="container">
            <Background imgSrc={reviewbg} alignX="center" alignY="bottom"/>
            <div className="wrapper wrapper_m dir_col gap_64">
                <div id="products" className="wrapper dir_col gap_24">
                    <div className="shop_step">
                        <div className="shop_step_number">{t('shop.product_step_number')}</div>
                        <h4>{t('shop.product_step_header')}</h4>
                    </div>
                    <div className="shop_items_container">

                    <ShopItem
                    imgSrc={starterpack}
                    bgSrc={starterpackshadow}
                    alt={t('shop.starterpack.alt')}
                    title={t('shop.starterpack.title')}
                    price={t('shop.starterpack.price')}
                    currency={t('shop.starterpack.currency')}
                    onValueChange={handleValueChange}
                    sections={[
                        {
                        title: t('shop.starterpack.section1.title'),
                        items: [
                            t('shop.starterpack.section1.item1'),
                            t('shop.starterpack.section1.item2'),
                            t('shop.starterpack.section1.item3'),
                            t('shop.starterpack.section1.item4'),
                            t('shop.starterpack.section1.item5')
                        ],
                        features: t('shop.starterpack.section1.features'),
                        url: "/tinyfarm" 
                        },
                        {
                        title: t('shop.starterpack.section2.title'),
                        items: [
                            t('shop.starterpack.section2.item1'),
                            t('shop.starterpack.section2.item2'),
                            t('shop.starterpack.section2.item3'),
                            t('shop.starterpack.section2.item4')
                        ],
                        features: t('shop.starterpack.section2.features'),
                        url: "/app" 
                        },
                        {
                        title: t('shop.starterpack.section3.title'),
                        items: [
                            t('shop.starterpack.section3.item1'),
                            t('shop.starterpack.section3.item2'),
                            t('shop.starterpack.section3.item3')
                        ]
                        },
                        {
                        title: t('shop.starterpack.section4.title'),
                        items: [
                            t('shop.starterpack.section4.item1'),
                            t('shop.starterpack.section4.item2')
                        ]
                        }
                    ]}
                    />
                    <ShopItem
                    imgSrc={plantpodpack}
                    bgSrc={plantpodpackshadow}
                    alt={t('shop.plantpodpack.alt')}
                    title={t('shop.plantpodpack.title')}
                    price={t('shop.plantpodpack.price')}
                    currency={t('shop.plantpodpack.currency')}
                    onValueChange={handleValueChange}
                    sections={[
                        {
                        items: [
                            t('shop.plantpodpack.item1'),
                            t('shop.plantpodpack.item2'),
                        ]
                        }
                    ]}
                    />

                    <ShopItem
                    imgSrc={nutrientpack}
                    bgSrc={nutrientpackshadow}
                    alt={t('shop.nutrientpack.alt')}
                    title={t('shop.nutrientpack.title')}
                    price={t('shop.nutrientpack.price')}
                    currency={t('shop.nutrientpack.currency')}
                    onValueChange={handleValueChange}
                    sections={[
                        {
                        items: [
                            t('shop.nutrientpack.item1'),
                            t('shop.nutrientpack.item2'),
                        ]
                        }
                    ]}
                    />

                    <ShopItem
                    imgSrc={plantpodnutrientpack}
                    bgSrc={plantpodnutrientpackshadow}
                    alt={t('shop.plantpodnutrientpack.alt')}
                    title={t('shop.plantpodnutrientpack.title')}
                    price={t('shop.plantpodnutrientpack.price')}
                    currency={t('shop.plantpodnutrientpack.currency')}
                    onValueChange={handleValueChange}
                    sections={[
                        {
                        items: [
                            t('shop.plantpodnutrientpack.item1'),
                            t('shop.plantpodnutrientpack.item2'),
                        ]
                        }
                    ]}
                    />

                    <ShopItemsFooter currency={t('shop.currency')}/>
                    </div>
                </div>
                <form className="wrapper dir_col gap_64" method='POST' onSubmit={handleFormSubmit}>
                    <div id="details_form" className="wrapper dir_col gap_24">
                        <div className="shop_step">
                            <div className="shop_step_number">{t('shop.detail_step_number')}</div>
                            <h4>{t('shop.detail_step_header')}</h4>
                        </div>
                        <AddressContactForm/>
                    </div>
                    <ShopReview/>
                </form>
            </div>
        </div>
      </>
    );
}
  
export default ShopPage;
