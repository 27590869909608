export const phone = '+45 20 93 16 33';
export const phoneURL = 'tel:+4520931633';
export const phone2 = '+45 22 55 79 89';
export const phoneURL2 = 'tel:+4522557989';


export const email = 'contact@tinyfar.ms';
export const emailURL = 'mailto:contact@tinyfar.ms';

export const linkedInHandle = 'thetinyfarms';
export const linkedInURL = 'https://www.linkedin.com/company/thetinyfarms';
