import React from 'react';
import { useTranslation } from 'react-i18next';
import './AppPage.css'
import {ContactForm}  from './sections/ContactForm'
import TeaserSection from './sections/TeaserSection'
import HeroSection from './sections/Hero'
import hero from '../assets/tinyfarm-hub/tinyfarm-hub-hero.png';
import heroshadow from '../assets/tinyfarm-hub/tinyfarm-hub-hero-shadow.png';
import herobg from '../assets/backgrounds/bg-5.svg';
import featuresbg1 from '../assets/backgrounds/bg-2.svg';
import featuresbg2 from '../assets/backgrounds/bg-4.svg';
import tinyfarmteaser from '../assets/tinyfarm-hub/tinyfarm-teaser.png';
import tinyfarmteasershadow from '../assets/tinyfarm-hub/tinyfarm-teaser-shadow.png';
import controlpanel from '../assets/tinyfarm-hub/tinyfarm-hub-control-panel.png';
import analytics from '../assets/tinyfarm-hub/tinyfarm-hub-analytics.png';
import programming from '../assets/tinyfarm-hub/tinyfarm-hub-programming.png';
import courses from '../assets/tinyfarm-hub/tinyfarm-hub-courses.png';
import {Button} from './uitools/Button'
import { Image } from './uitools/Image';
import { Background } from './uitools/Background';
import { useNavigate } from 'react-router-dom';


function SoftwareFeatureElem({ imgSrc, imgAlt, headerText, subText }){

  return(

    <div className="wrapper feature">
        <Image imgSrc={imgSrc} alt={imgAlt} className="feature_icon_img"/>
        <div className="wrapper dir_col gap_8">
          <h4 className="m0">{headerText}</h4>
          <p className="small">{subText}</p>
        </div>
    </div>
  )

}

function AppPage() {
  const { t } = useTranslation();

  let navigate = useNavigate();

  function handleNavigation(path) {
      navigate(path);
  }
  
  
  const teaserContent = 
  <>
      <div className="wrapper dir_col">
          <h2>{t('tinyfarm_hub.teaser_header')}</h2>
          <p>{t('tinyfarm_hub.teaser_subtext')}</p>
      </div>
      <Button style="primary" color="green" label={t('tinyfarm_hub.teaser_button')} onClick={() => handleNavigation("/tinyfarm")}/>
  </>;

return (
  <>
    <div id="hero" className="container">
      <Background imgSrc={herobg} alignX="center" alignY="top" offsetTop="-595" />
      <HeroSection headerOverlay={t('tinyfarm_hub.hero_headerOverlay')}
                    headerText={t('tinyfarm_hub.hero_header')}
                    imgSrc={hero}
                    imgBgSrc={heroshadow}
                    imgBlendMode="multiply"
                    heroSubtext={t('tinyfarm_hub.hero_subtext')}/>
    </div>
    <div id="hub_features" className="container gap_32">
      <Background imgSrc={featuresbg1} alignX="center" alignY="top" offsetTop="-152"/>
      <Background imgSrc={featuresbg2} alignX="center" alignY="bottom"/>
      <div className="wrapper gap_32">
        <SoftwareFeatureElem imgSrc={controlpanel} imgAlt={t('tinyfarm_hub.control_panel_imgAlt')} headerText={t('tinyfarm_hub.control_panel_header')} subText={t('tinyfarm_hub.control_panel_subtext')} />
        <SoftwareFeatureElem imgSrc={programming} imgAlt={t('tinyfarm_hub.programming_imgAlt')} headerText={t('tinyfarm_hub.programming_header')} subText={t('tinyfarm_hub.programming_subtext')} />
        <SoftwareFeatureElem imgSrc={analytics} imgAlt={t('tinyfarm_hub.analytics_imgAlt')} headerText={t('tinyfarm_hub.analytics_header')} subText={t('tinyfarm_hub.analytics_subtext')} />
        <SoftwareFeatureElem imgSrc={courses} imgAlt={t('tinyfarm_hub.courses_imgAlt')} headerText={t('tinyfarm_hub.courses_header')} subText={t('tinyfarm_hub.courses_subtext')} />
      </div>
    </div>
    <TeaserSection imgSrc={tinyfarmteaser} imgBgSrc={tinyfarmteasershadow} imgBlendMode="multiply" content={teaserContent} reverse={true}/>
    <ContactForm/>
  </>
);
}

export default AppPage;