import React from 'react';
import './TeaserSection.css';
import { Image } from '../uitools/Image';

function TeaserSection({ imgSrc, imgBgSrc, imgBlendMode, content, reverse }) {
    let classNames = "wrapper gap_32 align_center";
    if (reverse) {
        classNames += " dir_row_reverse";
    }
    return (
        <div className="teaser container">
            <div className={classNames}>
                <Image imgSrc={imgSrc} bgSrc={imgBgSrc} imgBlendMode={imgBlendMode} className="teaser_image"/>
                <div className="teaser_content wrapper dir_col align_start justify_center gap_24">
                    {content}
                </div>
            </div>
        </div>
    );
}

export default TeaserSection;
