import './FAQ.css';
import React, { useState } from 'react';
import faqbg1 from '../../assets/backgrounds/bg-2.svg';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Arrow } from '../../assets/icons/chevron_down-stroke-medium.svg';
import { Background } from '../uitools/Background';

export const BulletList = ({ items }) => (
  <ul className='small'>
    {items.map((item, index) => (
      <li key={index}>
        {item}
      </li>
    ))}
  </ul>
);

export function FAQItem({ question, answer }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="faq_items_item">
      <div className="faq_item_question" onClick={toggleExpanded}>
        <h5 className="m0">{question}</h5>
        <Arrow className={`faq_item_arrow ${isExpanded ? 'opened' : ''}`} />
      </div>
      {isExpanded && <div className="wrapper dir_col faq_item_answer">{answer}</div>}
    </div>
  );
}

function FAQSection() {
  const { t } = useTranslation();

  return (
    <div id="faqs" className="container gap_48">
      <Background imgSrc={faqbg1} alignX="center" alignY="top" offsetTop="20" />
      <div className="wrapper wrapper_s dir_col">
        <div className="faq_items_wrapper">
          <FAQItem
            question={t('faq.questions.sensors.title')}
            answer={
              <>
                <p className="small">{t('faq.questions.sensors.answer')}</p>
                <BulletList items={t('faq.questions.sensors.sensorList', { returnObjects: true })} />
              </>
            }
          />
          <FAQItem
            question={t('faq.questions.control.title')}
            answer={
              <>
                <p className="small">{t('faq.questions.control.answer')}</p>
                <BulletList items={t('faq.questions.control.controlList', { returnObjects: true })} />
              </>
            }
          />
          <FAQItem
            question={t('faq.questions.subjects.title')}
            answer={<p className="small">{t('faq.questions.subjects.answer')}</p>}
          />
          {/* <FAQItem
            question={t('faq.questions.customizable.title')}
            answer={<p className="small">{t('faq.questions.customizable.answer')}</p>}
          /> */}
          <FAQItem
            question={t('faq.questions.sdgs.title')}
            answer={
              <>
                <p className="small">{t('faq.questions.sdgs.answer')}</p>
                <h6>{t('faq.questions.sdgs.subGoals1.title')}</h6>
                <p className="small">{t('faq.questions.sdgs.subGoals1.description')}</p>
                <p className="small">{t('faq.questions.sdgs.subGoals1.additionalInfo')}</p>
                <h6>{t('faq.questions.sdgs.subGoals2.title')}</h6>
                <p className="small">{t('faq.questions.sdgs.subGoals2.description')}</p>
                <p className="small">{t('faq.questions.sdgs.subGoals2.additionalInfo')}</p>
                <h6>{t('faq.questions.sdgs.subGoals3.title')}</h6>
                <p className="small">{t('faq.questions.sdgs.subGoals3.description')}</p>
                <p className="small">{t('faq.questions.sdgs.subGoals3.additionalInfo')}</p>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default FAQSection;
