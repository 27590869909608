import React, { Suspense } from 'react';
import './Image.css';

function LazyImage({ src, className, alt }) {
  return <img src={src} className={className} alt={alt} />;
}

export function Image({ imgSrc, bgSrc, blendMode, className = '', alt = '' }) {
  if (bgSrc) {
    const imgClasses = `img ${className}`;
    const bgClasses = `background ${blendMode}`;
    return (
      <div className={imgClasses}>
        <Suspense fallback={<div>Loading background image...</div>}>
          <LazyImage src={bgSrc} className={bgClasses} alt={alt} />
        </Suspense>
        <Suspense fallback={<div>Loading foreground image...</div>}>
          <LazyImage src={imgSrc} className="foreground" alt={alt} />
        </Suspense>
      </div>
    );
  } else {
    return (
      <Suspense fallback={<div>Loading image...</div>}>
        <LazyImage src={imgSrc} className={className} alt={alt} />
      </Suspense>
    );
  }
}
