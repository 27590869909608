import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Hero.css'; 
import {Button} from '../uitools/Button'
import { Image } from '../uitools/Image';
import {ReactComponent as MailStroke} from '../../assets/icons/mail-stroke-medium.svg'; 
import {ReactComponent as Download} from '../../assets/icons/download-stroke-medium.svg'; 


function HeroSection({headerOverlay, headerText, imgSrc, imgBgSrc, imgBlendMode, heroSubtext, downloadURL, downloadLabel}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    // const handleClick = () => {
    //     const element = document.getElementById('book_demo');
    //     const distanceToTop = window.pageYOffset + element.getBoundingClientRect().top;

    // window.scrollTo({
    //     top: distanceToTop,
    //     behavior: 'smooth'
    // });

    return (
    <div className="wrapper wrapper_xs dir_col gap_32">
        <div className="wrapper dir_col text_align_center">
            <p className="preheader">{headerOverlay}</p>
            <h1>{headerText}</h1>
        </div>
        <Image imgSrc={imgSrc} bgSrc={imgBgSrc} blendMode={imgBlendMode} className={'hero_img_img'} alt={'hero_image'}/>
        <div className="wrapper dir_col text_align_center gap_24">
            <p className="m0">{heroSubtext}</p>
            <div className="wrapper justify_center align_center gap_16">
                <Button style="primary" 
                color="green" 
                Icon={MailStroke} 
                label={t('buttons.book_a_demo')}
                onClick={() => navigate("#book_demo")} />
                {downloadURL && downloadLabel ? (
                <Button style="secondary"
                color="green"
                Icon={Download}
                label={downloadLabel}
                onClick={() => navigate(downloadURL)} />) : ('') }
            </div>
        </div>
    </div>
    );
}
  
export default HeroSection;