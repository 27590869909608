import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const url = 'https://app.tinyfar.ms/login'; 
    window.location.href = url;
  }, [navigate]);  

  return null; 
}

export default LoginPage;
