import './FormCalendly.css';

function Calendly ({url}) {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    head.appendChild(script);
    
    return (
        <div 
        className={`calendly-inline-widget`}
        data-url={`${url}?hide_event_type_details=1&hide_gdpr_banner=1&text_color=1e322b&primary_color=746ce5`}
        />
    );
  }

  export default Calendly;