import './ConfirmPage.css';
import productsbg from '../assets/backgrounds/bg-5.svg';
import { ReactComponent as CheckCircle } from '../assets/icons/check_circle-fill-large.svg';
import { Background } from './uitools/Background';

function ConfirmPage () {

    return(
        <>
        <div id="hero" className="container">
            <Background imgSrc={productsbg} alignX="center" alignY="top" offsetTop="-595" />
            <div className="wrapper dir_col text_align_center">
                <h1>Invest in a sustainable future</h1>
                <p>Choose the perfect plan to cultivate curiosity and nurture knowledge in your classroom.</p>
            </div>
        </div>
        <div className="container">
            <div className="wrapper wrapper_m dir_col">
                <div className="confirm_container wrapper dir_col gap_16 align_center">
                    <CheckCircle className='confirm_icon'/>
                    <div className='wrapper dir_col gap_4 align_center text_align_center'>
                        <p className="bold m0">We got your order, thanks!</p>
                        <p className="xsmall">We'll get back to you soon with a confirmation, shipping price, and available payment options.</p>
                    </div>
                </div>
            </div>
        </div>

        </>
    );

}

export default ConfirmPage;