import './Languages.css'
import React, { useState, useEffect, useRef }  from 'react';
import i18n from "../../i18n.js";
import {ReactComponent as LanguagePickerIcon} from '../../assets/icons/globe-stroke-medium.svg'; 
import {ReactComponent as Arrow} from '../../assets/icons/chevron_down-stroke-small.svg'; 
import langs from 'langs'; 

const LanguagePicker = ({ theme }) => {
  const languages = Object.keys(i18n.options.resources);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language.slice(0, 2).toUpperCase());
  const [isOpen, setIsOpen] = useState(false);

  const wrapperRef = useRef(null);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage.toLowerCase());
  }, [selectedLanguage]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const outerClassName = theme === "white" ? "nav_text_item color_alt" : "nav_text_item";
  const innerClassName = theme === "white" ? "nav_language_item color_alt" : "nav_language_item";

  const otherLanguages = languages.filter((lang) => lang.toUpperCase() !== selectedLanguage);

  const fullLanguageName = (code) => {
    const languageInfo = langs.where('1', code.toLowerCase());
    return languageInfo ? languageInfo.local : code.toUpperCase();
  }

  return (
    <div className={`${outerClassName} nav_text_wrapper nav_language_selector`} ref={wrapperRef} onClick={() => setIsOpen(!isOpen)}>
      <div className={`nav_language_icon ${innerClassName}`}>
        <LanguagePickerIcon className="nav_language_img" />
      </div>
      <div className={`nav_language_text ${innerClassName}`}>
        {selectedLanguage}
        {isOpen && 
          <div className="language_dropdown green">
            {[selectedLanguage, ...otherLanguages].map((language) => (
              <div className="language_dropdown_item" key={language} onClick={(e) => {
                e.stopPropagation();
                setSelectedLanguage(language.toUpperCase());
                setIsOpen(false);
              }}>
                {fullLanguageName(language)}
              </div>
            ))}
          </div>
        }
      </div>
      <div className={`nav_language_arrow ${innerClassName}`}>
        <Arrow className={`nav_language_img ${isOpen ? 'opened' : ''}`} />
      </div>
    </div>
  );
}

export default LanguagePicker;
