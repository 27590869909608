import React, { useState }  from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Navbar.css'; 
import { Button } from './uitools/Button';
import LanguagePicker from './uitools/Languages';
import hero from '../assets/home/home-hero.png'; 
import {ReactComponent as Logo} from '../assets/logo/tinyfarms-logo-color.svg'; 
import {ReactComponent as LogoNeg} from '../assets/logo/tinyfarms-logo-color-neg.svg';
import {ReactComponent as MailStroke} from '../assets/icons/mail-stroke-medium.svg';
import {ReactComponent as Menu} from '../assets/icons/menu-stroke-medium.svg';
import {ReactComponent as Close} from '../assets/icons/x-stroke-medium.svg';
import { Background } from './uitools/Background';

export function Navbar({bgColor}) {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  function handleNavigation(path) {
    navigate(path);
    setIsOpen(false);
  }

  const navbarClasses = `container bg_${bgColor}`;

  return (
    <div id="navbar" className={navbarClasses}>
      <div className="wrapper">
        <Logo className="navbar_logo" onClick={() => handleNavigation("/")} />

        <div className="button secondary purple navbar_icon" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <Close /> : <Menu />}
        </div>

        <div className={`navbar_navigation ${isOpen ? 'open' : 'collapsed'}`}>
          <div className="nav_text_item" onClick={() => handleNavigation("/tinyfarm")}>{t('navbar.tinyfarm')}</div>
          <div className="nav_text_item" onClick={() => handleNavigation("/app")}>{t('navbar.app')}</div>
          <div className="nav_text_item" onClick={() => handleNavigation("/shop")}>{t('navbar.shop')}</div>
          <div className="nav_text_item" onClick={() => handleNavigation("/support")}>{t('navbar.support')}</div>
          <div className="nav_text_item" onClick={() => handleNavigation("/news")}>{t('navbar.news')}</div>
          <LanguagePicker />
          <Button style="primary" color="purple" label={t('buttons.login')} onClick={() => window.location.href="https://app.tinyfar.ms/login"}/>
        </div>
      </div>
    </div>
  );
}




export function NavbarHome() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  function handleNavigation(path) {
    navigate(path);
    setIsOpen(false);
  }

return(
  <div id="home" className="navbar_home_wrapper">
    <div id="navbar" className="container">
      <div className="wrapper">
        <LogoNeg className="navbar_logo" onClick={() => handleNavigation("/")} />

        <div className="button alt secondary navbar_icon" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <Close /> : <Menu />}
        </div>

        <div className={`navbar_navigation ${isOpen ? 'open' : 'collapsed'}`}>
          <div className="nav_text_item" onClick={() => handleNavigation("/tinyfarm")}>{t('navbar.tinyfarm')}</div>
          <div className="nav_text_item" onClick={() => handleNavigation("/app")}>{t('navbar.app')}</div>
          <div className="nav_text_item" onClick={() => handleNavigation("/shop")}>{t('navbar.shop')}</div>
          <div className="nav_text_item" onClick={() => handleNavigation("/support")}>{t('navbar.support')}</div>
          <div className="nav_text_item" onClick={() => handleNavigation("/news")}>{t('navbar.news')}</div>
          <LanguagePicker theme='white'/>
          <Button style="primary" color="green" label={t('buttons.login')} onClick={() => window.location.href="https://app.tinyfar.ms/login"}/>
        </div>
      </div>
    </div>
    <div id="hero" className="container">
      <div className="wrapper dir_col gap_24">
        <div className="wrapper dir_col text_align_center">
          <h1 className="color_alt">{t('home.hero_heading')}</h1>
          <p className="color_alt">{t('home.hero_subtext')}</p>
        </div>
        <div className="wrapper justify_center align_center gap_16">
          <Button style="primary" 
          alt color="purple" 
          Icon={MailStroke} 
          label={t('buttons.book_a_demo')} 
          onClick={() => navigate("#book_demo")}
          />
        </div>
      </div>
      <div className="home_hero_image">
        <Background imgSrc={hero} alignX="center" alignY="bottom" />
      </div>
    </div>
  </div>
);

}
