import React from 'react';
import './Chip.css';

export function Chip({Icon, label, className='', onClick}) {

    const chipClasses = `chip ${className}`;
    return (
        <div className={chipClasses} onClick={onClick}>
            <Icon className="chip_icon_svg"/>
            <div className="chip_label">{label}</div>
        </div>
    );

}