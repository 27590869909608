import './TinyFarmPage.css'
import {ContactForm}  from './sections/ContactForm'
import TeaserSection from './sections/TeaserSection'
import HeroSection from './sections/Hero'
import hero from '../assets/tinyfarm/tinyfarm-hero.png';
import heroshadow from '../assets/tinyfarm/tinyfarm-hero-shadow.png';
import herobg from '../assets/backgrounds/bg-5.svg';
import featuresbg from '../assets/backgrounds/bg-2.svg';
import tinyfarmhub from '../assets/tinyfarm/tinyfarm-hub-teaser.png';
import tinyfarmhubshadow from '../assets/tinyfarm/tinyfarm-hub-teaser-shadow.png';
import {Button} from './uitools/Button'
import {ReactComponent as Pods} from '../assets/tinyfarm/tinyfarm-feature-pods.svg'; 
import {ReactComponent as Camera} from '../assets/tinyfarm/tinyfarm-feature-camera.svg'; 
import {ReactComponent as Sensor} from '../assets/tinyfarm/tinyfarm-feature-sensor.svg'; 
import {ReactComponent as Light} from '../assets/tinyfarm/tinyfarm-feature-light.svg'; 
import {ReactComponent as Pump} from '../assets/tinyfarm/tinyfarm-feature-pump.svg';
import { Background } from './uitools/Background';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function HardwareFeature({ SvgFeature, headerText, subText }){

    return(

        <div className="card green align_start bg_transparent justify_start">
            <div className="card_icon_wrapper">
                <SvgFeature className="feature_icon_svg" />
            </div>
            <div className="wrapper dir_col gap_8">
                <h4 className="m0">{headerText}</h4>
                <p className="small">{subText}</p>
            </div>
        </div>

    )

}

function TinyFarmPage() {
    const { t } = useTranslation();
    let navigate = useNavigate();

    function handleNavigation(path) {
        navigate(path);
    }

    const teaserContent = 
    <>
        <div className="wrapper dir_col">
            <h2>{t('tinyfarm.teaser_header')}</h2>
            <p>{t('tinyfarm.teaser_subtext')}</p>
        </div>
        <Button style="primary" color="green" label={t('tinyfarm.teaser_button')} onClick={() => handleNavigation("/app")}/>
    </>;


    return (
      <>
        <div id="hero" className="container">
        <Background imgSrc={herobg} alignX="center" alignY="top" offsetTop="-595" />
        <HeroSection headerOverlay={t('tinyfarm.hero_header')}
                        headerText={t('tinyfarm.hero_text')}
                        imgSrc={hero}
                        imgBgSrc={heroshadow}
                        imgBlendMode="multiply"
                        heroSubtext={t('tinyfarm.hero_subtext')}
                        downloadLabel={t('tinyfarm.download_usermanual')}
                        downloadURL="/user-manual"/>
        </div>
        <div id="tinyfarm_features" className="container gap_32">
            <Background imgSrc={featuresbg} alignX="center" alignY="top" offsetTop="-152"/>
            <div className="wrapper gap_32">
            <HardwareFeature SvgFeature={Pods} headerText={t('tinyfarm.pod_header')} subText={t('tinyfarm.pod_subtext')}/>
            <HardwareFeature SvgFeature={Camera} headerText={t('tinyfarm.camera_header')} subText={t('tinyfarm.camera_subtext')}/>
            <HardwareFeature SvgFeature={Sensor} headerText={t('tinyfarm.sensor_header')} subText={t('tinyfarm.sensor_subtext')}/>
            <HardwareFeature SvgFeature={Light} headerText={t('tinyfarm.light_header')} subText={t('tinyfarm.light_subtext')}/>
            <HardwareFeature SvgFeature={Pump} headerText={t('tinyfarm.pump_header')} subText={t('tinyfarm.pump_subtext')}/>
            </div>
        </div>
        <TeaserSection imgSrc={tinyfarmhub} imgBgSrc={tinyfarmhubshadow} imgBlendMode="multiply" content={teaserContent} reverse/>
        <ContactForm/>
      </>
    );
  }
  
  export default TinyFarmPage;