import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './ContactForm.css'; 
import formbg from '../../assets/backgrounds/bg-3.svg';
import helpformbg from '../../assets/backgrounds/bg-2.svg';
import { Button } from '../uitools/Button';
import { PhoneDropdown } from '../uitools/Countries';
import { ReactComponent as Mail } from '../../assets/icons/mail-stroke-medium.svg'; 
import { ReactComponent as Check } from '../../assets/icons/check_circle-fill-medium.svg';
import { ReactComponent as Phone } from '../../assets/icons/phone-stroke-medium.svg';
import { useForm } from '@formspree/react';
import { Background } from '../uitools/Background';
import { useTranslation } from 'react-i18next';
import * as contactInfo from '../../contactInfo';
import FormFillout from '../uitools/FormFillout';
import FormCalendly from '../uitools/FormCalendly';
import { ReactComponent as Arrow } from '../../assets/icons/chevron_right-stroke-large.svg';

export function ContactFormItem({ id, title, description, expanded, children }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const contactFormItemRef = useRef(null);
  let navigate = useNavigate();
  const { hash } = useLocation();

  useEffect(() => {
    if (expanded) {
      setIsExpanded(true);
    }
  }, [expanded]);

  const observer = useRef(null);

  useEffect(() => {
    // Initialize the observer ref if it's not already done
    if (!observer.current) {
      observer.current = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            const target = mutation.target;
            if (target.classList.contains('open')) {
              setIsExpanded(true);
            }
          }
        });
      });
    }

    // Start observing
    if (contactFormItemRef.current) {
      observer.current.observe(contactFormItemRef.current, {
        attributes: true // Only listen to attribute changes
      });
    }

    // Cleanup
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []); // This effect should run once to setup the observer

  // useEffect(() => {
  //   if (contactFormItemRef.current && contactFormItemRef.current.className.includes('open')) {
  //     setIsExpanded(true);
  //   }
  // }, [contactFormItemRef.current?.className]);

  const toggleExpanded = () => {
    const targetHash = "#" + contactFormItemRef.current.id;
    setIsExpanded(!isExpanded);
    if (hash !== targetHash && isExpanded === false) {
      navigate(targetHash);
    }
  };

  useEffect(() => {
    if (isExpanded && contactFormItemRef.current) {
      setTimeout(() => {
        contactFormItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 50);
    }
  }, [isExpanded]);

  return (
    <div ref={contactFormItemRef} id={id} className={`contact-form-item form_container ${isExpanded ? 'open' : ''}`}>
      <div className="form_wrapper wrapper dir_col">
        <div className='contact-form-item-header wrapper gap_16 align_center' onClick={toggleExpanded}>
          <div className='contact-form-item-heading'>
            <h3 className='m0'>{title}</h3>
            <p className='small'>{description}</p>
          </div>
          <div className="contact-form-item-arrow">
            <Arrow />
          </div>
        </div>
        {children &&
          <div className="contact-form-item-content">
            {children}
          </div>
        }
      </div>
    </div>
  );
}

export function ContactForm() {
    const { t } = useTranslation();
    const [phone, setPhone] = useState('');
    const [state, handleSubmit] = useForm('xdovvbro');
  
    return (
      <div className="container">
        <Background imgSrc={formbg} alignX="center" alignY="center" />
        <div className="wrapper dir_col gap_48 align_center">
          <div className="wrapper dir_col text_align_center">
            <h1>{t('contactForm.heading')}</h1>
            <p>{t('contactForm.description')}</p>
          </div>
          <div className="wrapper wrapper_xs dir_col gap_24">
            <ContactFormItem
              id="book_demo"
              title={t('contactForm.demoHeading')}
              description={t('contactForm.demoDescription')}
            >
              <FormCalendly url="https://calendly.com/christian-tinyfarms" />
            </ContactFormItem>
            <ContactFormItem
              id="stay_in_touch"
              title={t('getInTouchForm.heading')}
              description={t('getInTouchForm.description')}
            >
              <FormFillout url={t('getInTouchForm.formId')} />
            </ContactFormItem>
            <div className="form_container contact-phone">
              <div className="form_wrapper wrapper align_center">
                <div className='wrapper dir_col gap_8'>
                  <h3 className='m0'>{t('contactForm.phoneHeading')}</h3>
                  <p className='small'>{t('contactForm.phoneSupport')}</p>
                  <Button
                    style="primary"
                    color="purple"
                    alt
                    Icon={Phone}
                    label={<h4>{contactInfo.phone}</h4>}
                    onClick={() => window.location.assign(contactInfo.phoneURL)}
                  />
                  <p className='small'>{t('contactForm.phoneDemo')}</p>
                  <Button
                    style="primary"
                    color="purple"
                    alt
                    Icon={Phone}
                    label={<h4>{contactInfo.phone2}</h4>}
                    onClick={() => window.location.assign(contactInfo.phoneURL2)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export function AddressContactForm() {
    const { t } = useTranslation();
    const [phone, setPhone] = useState('');
  
    return (
      <div className="form_container">
        <div className="form_wrapper wrapper dir_col">
          <div className="form">
            <div className="form_row required">
              <div className="form_item required">
                <label className="form_label" htmlFor="firstname">
                  {t('contactForm.firstName')}
                </label>
                <div className='form_input_wrapper'>
                  <input
                    className="form_input"
                    type="text"
                    name="firstname"
                    id="firstname"
                    placeholder={t('contactForm.firstNamePlaceholder')}
                    required
                  />
                </div>
              </div>
              <div className="form_item required">
                <label className="form_label" htmlFor="lastname">
                  {t('contactForm.lastName')}
                </label>
                <div className='form_input_wrapper'>
                  <input
                    className="form_input"
                    name="lastname"
                    type="text"
                    id="lastname"
                    placeholder={t('contactForm.lastNamePlaceholder')}
                    required
                  />
                </div>
              </div>
            </div>
  
            <div className="form_row">
              <div className="form_item required">
                <label className="form_label" htmlFor="email">
                  {t('contactForm.email')}
                </label>
                <div className='form_input_wrapper'>
                  <input
                    className="form_input"
                    type="email"
                    name="email"
                    id="email"
                    placeholder={t('contactForm.emailPlaceholder')}
                    required
                  />
                </div>
              </div>
              <div className="form_item">
                <label className="form_label" htmlFor="phone">
                  {t('contactForm.phone')}
                </label>
                <div className='form_input_wrapper'>
                  <PhoneDropdown phone={phone} setPhone={setPhone} />
                  <input type="hidden" name="phone" value={`+${phone}`} />
                </div>
              </div>
            </div>
  
            <div className="form_row">
              <div className="form_item">
                <label className="form_label" htmlFor="institution">
                  {t('contactForm.institution')}
                </label>
                <div className='form_input_wrapper'>
                  <input
                    className="form_input"
                    type="text"
                    name="institution"
                    id="institution"
                    placeholder={t('contactForm.institutionPlaceholder')}
                  />
                </div>
              </div>
            </div>
  
            <div className="form_row">
              <div className="form_item required">
                <label className="form_label" htmlFor="address">
                  {t('contactForm.address')}
                </label>
                <div className='form_input_wrapper'>
                  <input
                    className="form_input"
                    type="text"
                    name="address"
                    id="address"
                    placeholder={t('contactForm.addressPlaceholder')}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
export function TechContactForm() {
  const { t } = useTranslation();
  const [phone, setPhone] = useState('');
  const [state, handleSubmit] = useForm('xzbqlgvo');

  return (
    <div id="tech-contact" className="container gap_48">
      <Background imgSrc={helpformbg} alignX="center" alignY="top" offsetTop="20" />
      <div id="help_heading" className="wrapper wrapper_s dir_col text_align_center gap_8">
        <h3 className='m0'>{t('techContactForm.heading')}</h3>
        <p>{t('techContactForm.description')}</p>
      </div>
      <div className="wrapper wrapper_s dir_col">
        <div className="form_container">
          <div className="form_wrapper wrapper dir_col">
            <form className="form" onSubmit={handleSubmit}>
              <div className="form_row required">
                <div className="form_item required">
                  <label className="form_label" htmlFor="firstname">
                    {t('contactForm.firstName')}
                  </label>
                  <div className="form_input_wrapper">
                    <input
                      className="form_input"
                      type="text"
                      name="firstname"
                      id="firstname"
                      placeholder={t('contactForm.firstNamePlaceholder')}
                      required
                    />
                  </div>
                </div>
                <div className="form_item required">
                  <label className="form_label" htmlFor="lastname">
                    {t('contactForm.lastName')}
                  </label>
                  <div className="form_input_wrapper">
                    <input
                      className="form_input"
                      name="lastname"
                      type="text"
                      id="lastname"
                      placeholder={t('contactForm.lastNamePlaceholder')}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="form_row">
                <div className="form_item required">
                  <label className="form_label" htmlFor="email">
                    {t('contactForm.email')}
                  </label>
                  <div className="form_input_wrapper">
                    <input
                      className="form_input"
                      type="email"
                      name="email"
                      id="email"
                      placeholder={t('contactForm.emailPlaceholder')}
                      required
                    />
                  </div>
                </div>
                <div className="form_item">
                  <label className="form_label" htmlFor="phone">
                    {t('contactForm.phone')}
                  </label>
                  <div className="form_input_wrapper">
                    <PhoneDropdown phone={phone} setPhone={setPhone} />
                    <input type="hidden" name="phone" value={`+${phone}`} />
                  </div>
                </div>
              </div>

              <div className="form_row">
                <div className="form_item">
                  <label className="form_label" htmlFor="institution">
                    {t('contactForm.institution')}
                  </label>
                  <div className="form_input_wrapper">
                    <input
                      className="form_input"
                      type="text"
                      name="institution"
                      id="institution"
                      placeholder={t('contactForm.institutionPlaceholder')}
                    />
                  </div>
                </div>
              </div>

              <div className="form_row">
                <div className="form_item required">
                  <label className="form_label" htmlFor="subject">
                    {t('techContactForm.subject')}
                  </label>
                  <div className="form_input_wrapper">
                    <input
                      className="form_input"
                      type="text"
                      name="tech-subject"
                      id="subject"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form_row">
                <div className="form_item required">
                  <label className="form_label" htmlFor="message">
                    {t('techContactForm.message')}
                  </label>
                  <div className="form_input_wrapper">
                    <textarea
                      rows="4"
                      className="form_input form_input_big"
                      id="message"
                      name="message"
                      required
                    ></textarea>
                  </div>
                </div>
              </div>
              <Button style="primary" color="purple" Icon={Mail} label={t('techContactForm.submit')} type="submit" />
            </form>
          </div>
          {state.succeeded && (
            <div className="contact_form_confirmed">
              <Check />
              <p className="tiny bold">{t('techContactForm.confirmationMessage')}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}


export function HelpContactForm() {
  const { t } = useTranslation();
  const [phone, setPhone] = useState('');
  const [state, handleSubmit] = useForm('xdovvbro');

  return (
    <div id="help-contact" className="container gap_48">
      <Background imgSrc={helpformbg} alignX="center" alignY="top" offsetTop="20" />
      <div id="help_heading" className="wrapper wrapper_s dir_col text_align_center gap_8">
        <h3 className='m0'>{t('helpContactForm.heading')}</h3>
        <p>{t('helpContactForm.description')}</p>
      </div>
      <div className="wrapper wrapper_s dir_col">
        <div className="form_container">
          <div className="form_wrapper wrapper dir_col">
            <form className="form" onSubmit={handleSubmit}>
              <div className="form_row required">
                <div className="form_item required">
                  <label className="form_label" htmlFor="firstname">
                    {t('contactForm.firstName')}
                  </label>
                  <div className="form_input_wrapper">
                    <input
                      className="form_input"
                      type="text"
                      name="firstname"
                      id="firstname"
                      placeholder={t('contactForm.firstNamePlaceholder')}
                      required
                    />
                  </div>
                </div>
                <div className="form_item required">
                  <label className="form_label" htmlFor="lastname">
                    {t('contactForm.lastName')}
                  </label>
                  <div className="form_input_wrapper">
                    <input
                      className="form_input"
                      name="lastname"
                      type="text"
                      id="lastname"
                      placeholder={t('contactForm.lastNamePlaceholder')}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="form_row">
                <div className="form_item required">
                  <label className="form_label" htmlFor="email">
                    {t('contactForm.email')}
                  </label>
                  <div className="form_input_wrapper">
                    <input
                      className="form_input"
                      type="email"
                      name="email"
                      id="email"
                      placeholder={t('contactForm.emailPlaceholder')}
                      required
                    />
                  </div>
                </div>
                <div className="form_item">
                  <label className="form_label" htmlFor="phone">
                    {t('contactForm.phone')}
                  </label>
                  <div className="form_input_wrapper">
                    <PhoneDropdown phone={phone} setPhone={setPhone} />
                    <input type="hidden" name="phone" value={`+${phone}`} />
                  </div>
                </div>
              </div>

              <div className="form_row">
                <div className="form_item">
                  <label className="form_label" htmlFor="institution">
                    {t('contactForm.institution')}
                  </label>
                  <div className="form_input_wrapper">
                    <input
                      className="form_input"
                      type="text"
                      name="institution"
                      id="institution"
                      placeholder={t('contactForm.institutionPlaceholder')}
                    />
                  </div>
                </div>
              </div>

              <div className="form_row">
                <div className="form_item required">
                  <label className="form_label" htmlFor="subject">
                    {t('helpContactForm.subject')}
                  </label>
                  <div className="form_input_wrapper">
                    <input
                      className="form_input"
                      type="text"
                      name="tech-subject"
                      id="subject"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form_row">
                <div className="form_item required">
                  <label className="form_label" htmlFor="message">
                    {t('helpContactForm.message')}
                  </label>
                  <div className="form_input_wrapper">
                    <textarea
                      rows="4"
                      className="form_input form_input_big"
                      id="message"
                      name="message"
                      required
                    ></textarea>
                  </div>
                </div>
              </div>
              <Button style="primary" color="purple" Icon={Mail} label={t('helpContactForm.submit')} type="submit" />
            </form>
          </div>
          {state.succeeded && (
            <div className="contact_form_confirmed">
              <Check />
              <p className="tiny bold">{t('helpContactForm.confirmationMessage')}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}