import React from 'react';
import './Background.css';

export function Background({ imgSrc, alignX, alignY, offsetTop, offsetBottom, offsetLeft, offsetRight }) {
    const offset = {
        top: `${offsetTop}px`,
        bottom: `${offsetBottom}px`,
        left: `${offsetLeft}px`,
        right: `${offsetRight}px`
      };
    const imgClasses = `bg x-${alignX} y-${alignY}`;
    return (
        <img src={imgSrc} className={imgClasses} style={offset} alt="background_image"></img>
    )
};